<template>
	<div class="order_wrap">
		<v-container class="fixed_wrap">
			<div class="txt_box_0">
				<div class="txt_type_01 1 px-6">
					<div>
						{{ name }} 님의 주문을 삭제합니다. <br />
						아래 주문 내용을 확인 후 삭제 확인을 해주세요.
					</div>

					<ul class="info_list my-5">
						<li>
							<dt>고객명</dt>
							<dd>{{ name }}</dd>
						</li>
						<li>
							<dt>전화번호</dt>
							<dd>{{ phone }}</dd>
						</li>
						<li>
							<dt>주문번호</dt>
							<dd>
								{{ orderNo }}
							</dd>
						</li>
					</ul>
				</div>
				<h3
					data-v-07a65019=""
					class="bg_gray_tit mb-6 d-flex justify-space-between"
				>
					주문요약
					<span class="primary--text"
						>총 <b>{{ orderList.length }}</b
						>개</span
					>
				</h3>
				<template v-for="(ord, i) in orderList">
					<div class="tit_box px-6" :key="i">
						<div class="impact_tit">
							<h3>
								주문{{ i + 1 }}.
								<strong
									:class="ord.orderCancelYn ? 'ft-orange' : 'primary--text'"
									>{{ ord.GOODS_NM ? ord.GOODS_NM : deliveryInfos[i].prodNm }}
									{{
										getPrdName(ord.PRODH9) === '기타'
											? getPrdName(deliveryInfos[i].PRODH9)
											: getPrdName(ord.PRODH9)
									}}</strong
								>
							</h3>
						</div>
						<div class="info_list bor_n py-3 mb-6">
							<dl>
								<dt>주문번호</dt>
								<dd>{{ ord.ORDER_NO }}</dd>
							</dl>
							<dl>
								<dt>구매방식</dt>
								<dd>{{ ord.E_SALE_GUBUN }}</dd>
							</dl>
							<dl v-if="ord.GOODS_AMT > 0 && !ord.orderCancelYn">
								<dt>수납정보</dt>
								<dd>
									{{ ord.GOODS_AMT | comma }} 원<br />{{
										ord.T_A0401_PAYMENT_LIST.filter(x => x.GUBUN === '2')[0]
											? ord.T_A0401_PAYMENT_LIST.filter(x => x.GUBUN === '2')[0]
													.CARDCOM_NM
											: ''
									}}
									{{
										getMaskedAccNo(
											ord.T_A0401_PAYMENT_LIST.filter(x => x.GUBUN === '2')[0]
												? ord.T_A0401_PAYMENT_LIST.filter(
														x => x.GUBUN === '2'
												  )[0].CARD_NO
												: ''
										)
									}}
								</dd>
							</dl>
							<dl v-if="ord.MONTH_AMT > 0 && !ord.orderCancelYn">
								<dt>정기결제</dt>
								<dd>
									{{ ord.MONTH_AMT | comma }} 원/월<br />{{ ord.CARDCOM_NM }}
									{{ ord.CARD_NO ? getMaskedAccNo(ord.CARD_NO) : '' }}
								</dd>
							</dl>

							<dl v-if="!ord.orderCancelYn">
								<dt>설치주소</dt>
								<dd>{{ ord.CITY1 }} {{ ord.STREET }}</dd>
							</dl>
							<dl v-if="!ord.orderCancelYn">
								<dt>설치일시</dt>
								<dd v-if="isPreContract" class="ft-blue">
									{{
										deliveryInfos[i].workerType !== 'EXPRESS'
											? ord.E_YYYY_MM + ' 배정 진행 가능'
											: '택배 배송'
									}}
								</dd>
								<dd v-else>
									{{
										deliveryInfos[i].workerType !== 'EXPRESS'
											? dateToBookingText({
													bookingDate: deliveryInfos[i].bookingDate,
													bookingSlot: deliveryInfos[i].bookingSlot,
													bookingSlotTime: deliveryInfos[i].bookingSlotTime
											  })
											: '택배 배송'
									}}
								</dd>
							</dl>
							<dl v-if="ord.orderCancelYn">
								<dt>주문상태</dt>
								<dd>주문삭제</dd>
							</dl>
						</div>
					</div>
				</template>
				<template v-if="false">
					<h3 data-v-07a65019="" class="bg_gray_tit mb-6">환불계좌정보</h3>
					<v-form class="wrap_section px-6" ref="cancelForm">
						<div class="wrap_input mb-0">
							<h3 class="label">예금주</h3>
							<v-text-field
								outlined
								disabled
								dense
								:placeholder="name"
								v-model="form.custName"
								:rules="rules.required"
								inputmode="text"
								:maxlength="$Constants.MAX_CUST_NAME_LENGTH"
							/>
							<p class="txt_warn_small mt-2 mb-0">
								※ 주문자의 계좌로만 환불 가능하여, 수정이 불가능합니다
							</p>
						</div>
						<div class="wrap_input">
							<h3 class="label">은행</h3>
							<BankCompanyBottom
								class="btn_all"
								title="은행 선택"
								v-model="form.bankCd"
								@item-selected="handleBankCompanySelected"
							>
								<template
									>{{ form.bankNm }}<span class="btn_value"></span
								></template>
							</BankCompanyBottom>
						</div>
						<div class="wrap_input">
							<h3 class="label">계좌번호</h3>
							<v-text-field
								outlined
								placeholder="‘-’없이 입력해주세요"
								dense
								v-model="form.accountNo"
								:rules="rules.accountNo"
								inputmode="numeric"
								v-mask="['###########', '#########################']"
								maxlength="25"
							/>
						</div>
					</v-form>
				</template>
			</div>
			<div class="bottom_box">
				<v-btn class="btn_col2 col-5" @click="back()"> 이전 </v-btn>

				<v-btn color="primary" class="btn_col2 col-7" @click="cancel()">
					주문 삭제하기
				</v-btn>
			</div>
		</v-container>
	</div>
</template>

<script>
import {
	checkingStatus,
	mOrderInfoByEtc,
	postWebOrderInfoById,
	fetchWebOrderInfoById
} from '@/apis/order.status.api'
import filters from '@/mixins/filters'
import { fetchEContract, postOrderCancel } from '@/apis/order.ec.api'
import { dateToBookingText } from '@/utils/schedule.js'
import { required, maxLength, minLength, numeric } from '@/utils/rules'
import { getPrdName, getMessageByStatusCode, getMaskedAccNo } from '@/utils/ec'
import { registerCallbackToApp } from '@/utils/navigation'
import { isParameterEmpty } from '@/utils/common'

export default {
	name: 'OrderStatusCancel',
	mixins: [filters],
	data() {
		return {
			bankCompanyDisplayText: '은행입력',
			name: '',
			phone: '',
			orderNo: '',
			orderList: [],
			recpAmt: '',
			recpInfos: {},
			mthd: '',
			orderCancelYn: false,
			isBankType: false,
			form: {
				accountNo: '',
				custName: '',
				bankCd: '',
				bankNm: '은행입력'
			},
			rules: {
				name: [v => required(v), v => minLength(v, 2), v => maxLength(v, 10)],
				accountNo: [v => required(v), v => numeric(v), v => minLength(v, 11)],
				required: [v => required(v)]
			},
			isPreContract: this.$store.state['verify'].customer.orderType === 'C'
		}
	},
	created() {
		this.initAppPage()
	},
	async mounted() {
		const mobOrderNo = this.$store.getters['verify/mobOrderNo']
		if (this.isParameterEmpty(mobOrderNo)) return
		let params = { mobOrderNo: mobOrderNo }
		const res = await fetchEContract(params)
		this.orderList = res.resultObject.data[0].setup
		this.deliveryInfos = this.$store.getters['schedule/deliveryInfos']

		let ordNoStr = ''
		this.orderList.forEach((res, i) => {
			if (i === 0) {
				ordNoStr = `${res.ORDER_NO}`
			} else {
				ordNoStr = `${ordNoStr}, ${res.ORDER_NO}`
			}
		})
		this.orderNo = ordNoStr

		//주문상태조회
		await this.createOrderStatusList()
		const allCanceledOrder = await this.allCanceledOrderChkAndUpdate()
		if (allCanceledOrder) {
			this.$router.push({ name: 'order-status' })
			return
		}

		// 일반 : 성명, 개인사업자: 사업자명
		const custType = this.$store.getters['verify/customerType']
		if (custType === 'L') {
			this.name = this.$store.getters['verify/bizName']
		} else {
			this.name = this.$store.getters['verify/custName']
		}

		this.phone = this.$store.getters['verify/custPhone']
		this.recpAmt = this.$store.getters['pay/recpAmt']
		this.recpInfos = this.$store.getters['recp/recpInfos']

		this.$log('this.recpInfos.mthd: ', this.recpInfos.mthd)
		if (this.recpAmt > 0 && this.recpInfos.mthd) {
			this.form.custName = this.name
			this.mthd = this.recpInfos.mthd
			if (
				this.mthd === 'AA' ||
				this.mthd === '11' ||
				this.mthd === '16' ||
				this.mthd === '90'
			) {
				// 은행이체, 가상계좌, 복합결제
				this.isBankType = true
			}
		}
	},
	methods: {
		getPrdName,
		getMaskedAccNo,
		dateToBookingText,
		isParameterEmpty,
		open() {
			this.dialogVisible = true
		},
		async back() {
			sessionStorage.removeItem('customer')
			this.$router.go(-1)
		},
		handleBankCompanySelected(item) {
			this.$log(item)
			this.form.bankNm = item.text
		},
		getPayNo(no) {
			let maskingNo = ''
			const payOrd = this.$store.getters['pay/paymentOrders']

			this.$log(payOrd)
			payOrd.forEach(v => {
				let cardNoLength = no.length
				let firstNo = ''

				let star = ''
				if (v.PAY_MTHD === '11' && no === v.CARD_NO) {
					firstNo = no.substr(0, 4)
					for (let i = 0; i < cardNoLength - firstNo.length; i++) {
						star = star + '*'
						maskingNo = firstNo + star
					}
				} else if (v.PAY_MTHD === '12' && no === v.CARD_NO) {
					firstNo = no.substr(0, 6)
					for (let i = 0; i < cardNoLength - firstNo.length; i++) {
						star = star + '*'
						maskingNo = firstNo + star
					}
				}
			})
			this.$log('maskingNo', maskingNo)
			return maskingNo
		},
		getCancelOrdPrdNm(name) {
			let prdNm = ''
			this.products = this.$store.getters['pay/payProducts']
			this.products.forEach(v => {
				if (v.prd.prod_nm === name) {
					prdNm = v.options.prdha
				}
			})
			return prdNm
		},
		async createOrderStatusList() {
			const orderNoList = []
			for (const order of this.orderList) {
				orderNoList.push(order.ORDER_NO)
			}

			const response = await checkingStatus({
				saleCd: this.$store.getters['common/codyNo'],
				kunnr: this.$store.getters['verify/custNo'],
				orderNos: orderNoList
			})

			const orderStatusList = response.resultObject.T_ORDER_STATUS
			orderStatusList.forEach((v, i) => {
				if (v.ORDER_STATUS === '203') {
					this.orderList[i].orderCancelYn = true
				}
			})
		},
		async allCanceledOrderChkAndUpdate() {
			const cancelYn = this.orderList.every(v => v.orderCancelYn === true)
			this.$log('all order status cancel : ', cancelYn)
			if (cancelYn) {
				const mobOrderNo = this.$store.getters['verify/mobOrderNo']
				const res = await fetchWebOrderInfoById(mobOrderNo)
				const webParams = res.resultObject
				webParams.statusCode = '203'

				await postWebOrderInfoById(mobOrderNo, webParams)

				const msg = getMessageByStatusCode('203')
				await this.$alert({
					message: msg
				})
			}

			return cancelYn
		},
		async cancel() {
			const mobOrderNo = this.$store.getters['verify/mobOrderNo']
			if (this.isParameterEmpty(mobOrderNo)) return

			let arrParam = {
				id: mobOrderNo, // 웹주문번호
				statusCode: '203'
			}
			const res = await mOrderInfoByEtc(arrParam)
			//'108' 일때만 주문삭제 가능
			if (res.resultObject.statusCode !== '108') {
				let msg = getMessageByStatusCode(res.resultObject.statusCode)
				if (msg) {
					await this.$alert({
						message: msg
					})
				}
				sessionStorage.removeItem('customer')
				this.$router.push({
					name: 'order-status',
					query: {
						user_no: sessionStorage.getItem('codyNoEnc'),
						dept_cd: this.$store.getters['common/deptCd'],
						type: this.$store.getters['common/type']
					}
				})
				return
			}

			let isValid = true
			if (!isValid) return

			const altRes = await this.$confirm({
				message:
					'주문을 삭제하시겠습니까?<br />한 번 삭제된 주문은 영구삭제됩니다.',
				okText: '삭제하기',
				cancelText: '닫기'
			})

			if (altRes) {
				let cancelPrm = {
					mobOrderNo: this.$store.getters['verify/mobOrderNo'],
					userId: this.$store.getters['common/codyNo'],
					kunnr: this.$store.getters['verify/custNo'],
					name:
						this.$store.getters['verify/customerType'] === 'L'
							? this.$store.getters['verify/bizName']
							: this.$store.getters['verify/custName'],
					mob: this.$store.getters['verify/custPhone']
				}

				const res = await postOrderCancel(cancelPrm)
				const rtn = res.resultObject.E_RETURN
				const rtnEtStatus = res.resultObject.ET_STATUS

				const remainsFail = []
				const deleteFail = []

				// rtn.CODE 1 : 전체 삭제 성공
				//          2 : 부분 삭제 성공
				//          '' : 전체 삭제 실패
				const deleteAllChk = rtn.CODE === '1'
				rtnEtStatus.forEach(v => {
					if (v.STATUS && v.TYPE === 'F') {
						const detail = {
							orderNo: v.ORDER_NO,
							status: v.STATUS,
							statusName: v.STATUS_T
						}
						// SAP 주문 상태 = 99 : 주문삭제, 06 : 주문취소
						!deleteAllChk && (v.STATUS === '99' || v.STATUS === '06')
							? deleteFail.push(detail) // 이미 주문취소, 삭제 된 상태
							: remainsFail.push(detail)
					}
				})

				if (
					deleteAllChk ||
					(!deleteAllChk && rtnEtStatus.length > 0 && remainsFail.length === 0)
				) {
					// 웹 주문정보 저장
					const mobOrderNo = this.$store.getters['verify/mobOrderNo']
					const res = await fetchWebOrderInfoById(mobOrderNo)

					let webParams = res.resultObject
					webParams.step = '10' // 주문삭제
					webParams.statusCode = '203' // 주문삭제

					let order = JSON.parse(webParams.orderInfoJson)

					order = Object.assign(order, {
						cancelPrm
					})
					webParams.orderInfoJson = JSON.stringify(order)
					await postWebOrderInfoById(mobOrderNo, webParams)

					if (deleteFail.length > 0) {
						let msg =
							'모든 주문이 삭제되었으나 다음 주문의 삭제요청은 실패했습니다.<br />'
						deleteFail.forEach(v => {
							msg += '<br />'.concat(
								v.orderNo,
								': 요청 전 ',
								v.statusName,
								' 상태'
							)
						})
						//전체주문삭제
						await this.$alert({
							message: msg
						})
					} else {
						await this.$alert({
							message:
								'모든 주문이 삭제됐습니다.<br />주문현황 화면으로 이동합니다.'
						})
					}
					this.$router.push({ name: 'order-status' })
				} else {
					let msg = '다음 주문의 삭제요청이 실패했습니다.<br />'
					remainsFail.forEach(v => {
						msg += '<br />'.concat(v.orderNo, ': ', v.statusName, ' 상태')
					})
					msg += '<br /> 상세 내용은 지국을 통해서 확인해주세요.'

					if (rtnEtStatus.length === 0) msg = rtn.MESSAGE
					await this.$alert({
						message: msg
					})
					await this.createOrderStatusList()
					return
				}
			}
		},
		initAppPage() {
			registerCallbackToApp({ title: '주문삭제' })
		}
	}
}
</script>

<style scoped lang="scss"></style>
